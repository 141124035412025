
export class ClientCredential {
  public static createEmpty(): ClientCredential {
    // entity of chat
    return new ClientCredential();
  }
  constructor(
    public UserId: string = '',
    public UserToken: string = '',
    public TeamId: string = '',
    public EventId: number = 0,
    public EventName: string = '',
  ) { }
}
